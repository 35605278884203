main.noticies {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
	margin-bottom: 20px;
	width: 80%;
	max-width: 1400px;

	&:has(article.noticia) {
		flex-direction: row;
		gap: 20px;

		@media (max-width: 768px) {
			flex-direction: column;
			width: 100%;
		}
	}

	article.noticia {
		grid-area: noticia;
	}

	aside {
		grid-area: aside;
		display: flex;
		justify-content: flex-start;
		place-self: flex-start;
		align-items: flex-start;
		gap: 20px;
		flex-direction: column;
		padding: 0 20px;
		border-left: 1px solid #aeaeae;

		@media (max-width: 768px) {
			border-left: none;
			width: 100%;

			ul li {
				max-width: 100%;
			}
		}

		h2 {
			font-size: 1.6rem;
		}

		ul {
			list-style-type: none;
			padding: 0;
			margin: 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: 20px;
			flex-direction: column;

			li {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 10px;
				flex-direction: row;
				width: 100%;
				max-width: 300px;
				border: 1px solid #f0f0f0;
				border-radius: 5px;
				transition: background 0.3s ease;

				&:hover {
					background: #f0f0f0;
				}

				a {
					text-decoration: none;
					color: black;
					font-size: 1.2rem;
					text-wrap: balance;
					width: 100%;
					height: 100%;
					padding: 10px;
				}
			}
		}
	}

	ul.noticies {
		list-style-type: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: stretch;
		align-items: stretch;
		gap: 20px;
		flex-direction: row;

		li.noticia {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 10px;
			flex-direction: column;
			border-radius: 5px;
			transition: background 0.3s ease;
			width: calc(25% - 15px);

			a {
				height: 100%;
			}
		}
	}
}