// #0b57d0
// #91D6AC

aside.menu {
  --accentColor: #0b57d0;
  background-color: var(--backgroundColor);
  box-shadow: 0 0 8px 0px rgba(220, 220, 220, 0.2);
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: flex-start;
  float: inline-start;
  width: 360px;
  transition: all .2s ease-in-out;
  z-index: 100;

  @media screen and (max-width: 480px) {
    display: none;
  }

  &[data-state="closed"] {
    width: 80px;

    span.toggleNav.altStyle:hover {
      &::before {
        transform: rotate(11deg) !important;
      }

      &::after {
        transform: rotate(-11deg) !important;
      }
    }

    span.toggleNav svg {
      transform: rotate(180deg);
    }

    header {
      overflow: hidden;

      img {
        width: 60px;
        height: 60px;
      }

      h1 {
        display: none;
      }
    }

    section.accentColor {
      padding: 0 20px;
      gap: 30px;

      span.color {
        width: 40px;
        height: 40px;
      }
    }

    nav ul li.section {
      padding: 0px 15px 0px 15px;

      button.toggleSubmenu * {
        display: none !important;
      }

      ul.subSections li.subSection a {
        padding: 0 15px;
      }

      &:not(.active):not(:has(ul.subSections.open)) :is(a, button):active {
        transform: scale(0.9);

        svg {
          transform: scale(1.1);
        }
      }
    }
  }

  span.toggleNav {
    display: flex;
    box-shadow: -10px 0px 0 0 #fff, 0px 0px 10px 0 rgba(0, 0, 0, 0.1);
    position: absolute;
    top: calc(50% - 40px);
    right: -20px;
    background: white;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 20px;
    cursor: pointer;
    border-radius: 5px;

    &.altStyle {
      display: flex;
      position: absolute;
      top: calc(50% - 20px);
      right: -30px;
      box-shadow: none;
      height: 40px;
      width: 30px;
      background: transparent;

      svg {
        display: none;
      }

      &:hover {
        &::before {
          transform: rotate(-11deg);
        }

        &::after {
          transform: rotate(11deg);
        }
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 10px !important;
        height: 23px !important;
        transform: none;
        border: none;
        transition: all .2s ease-in-out;
      }

      &::before {
        background: #333;
        border-radius: 3px 3px 5px 5px;
        bottom: 0;
      }

      &::after {
        background: #333;
        border-radius: 5px 5px 3px 3px;
        top: 0;
      }
    }

    svg {
      height: 1.6rem;
      width: 1.6rem;
      transition: all .2s ease-in-out;

      path {
        stroke: #333;
      }
    }

    &::before {
      content: '';
      transform: rotate(45deg);
      position: absolute;
      display: flex;
      height: 20px;
      width: 20px;
      bottom: -30px;
      border: 10px solid;
      border-color: transparent transparent transparent #fff;
      border-radius: 50%;
      background: transparent;
    }

    &::after {
      content: '';
      transform: rotate(-45deg);
      position: absolute;
      display: flex;
      height: 20px;
      width: 20px;
      top: -30px;
      border: 10px solid;
      border-color: transparent transparent transparent #fff;
      border-radius: 50%;
      background: transparent;
    }
  }

  header a {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 10px;
    gap: 10px;
    justify-content: flex-start;

    img {
      height: 80px;
      width: 80px;
      transition: all .2s ease-in-out;
      filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.8));
    }

    h1 {
      font-size: 2.4rem;
      text-wrap: balance;
      font-weight: 600;
      text-align: start;
      color: rgba($color: var(--contrastColor), $alpha: 1.0)
    }
  }

  nav {
    display: flex;
    padding: 20px 0;
    min-height: calc(100vh - 140px);
    width: 100%;

    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      overflow: hidden;

      li.section {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 50px 0fr;
        width: 100%;
        padding: 0 15px;
        cursor: pointer;
        transition: all .2s ease-in-out;
        overflow: hidden;
        border-radius: 10px;

        &:has(ul.subSections:not(.open)>li.active)::after {
          content: '';
          position: absolute;
          top: 15px;
          left: 12px;
          width: 6px;
          height: 20px;
          border-radius: 3px;
          color: #fff;
          background: var(--accentColor);
          font-size: 3.2rem;
          transition: all .2s ease-in-out;
        }

        &:has(ul.subSections.open) {
          grid-template-rows: 50px 1fr;
          height: fit-content;

          a {
            border-radius: 10px 10px 0 0;
          }

          &::before {
            content: '';
            position: absolute;
            top: 0px;
            left: 15px;
            width: calc(100% - 34px);
            height: calc(100% - 4px);
            border-radius: 8px;
            border: 2px solid #d3d3d3;
            transition: all .2s ease-in-out;
          }

          &.active::before {
            border-color: var(--accentColor);
          }
        }

        ul.subSections {
          display: flex;
          flex-direction: column;
          gap: 0px;
          width: 100%;
          padding: 0;
          min-height: 0;
          transition: all .2s ease-in-out;
          overflow: hidden;
          border-radius: 0 0 10px 10px;

          li.subSection {
            width: 100%;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            transition: background .2s ease-in-out;

            &.active {
              background: color-mix(in srgb, var(--accentColor) 5%, transparent);

              a svg :is(path, circle),
              span {
                color: var(--accentColor);
                stroke: var(--accentColor) !important;
              }
            }

            &:hover {
              background: rgba($color: #898, $alpha: .1);
            }

            a {
              width: 100%;
              height: 30px;
              display: grid;
              grid-template-columns: 20px 1fr;
              align-items: center;
              padding: 0 15px;
              gap: 10px;

              svg {
                height: 2.0rem;
                width: 2.0rem;
                display: inline-flex;
                margin-left: 1px;

                path {
                  stroke: var(--highEmphasisText);
                }
              }

              span {
                font-size: 1.6rem;
                font-weight: 400;
                color: var(--highEmphasisText);
                text-transform: capitalize;
                text-align: start;
                text-wrap: nowrap;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: flex;
              }
            }
          }
        }

        &.active>:is(a, button) {
          background: color-mix(in srgb, var(--accentColor) 5%, transparent);
          transition: none;

          div {

            svg :is(path, circle),
            span {
              stroke: var(--accentColor);
              color: var(--accentColor);
            }
          }
        }

        &:not(.active):hover>:is(a, button) {
          background: rgba($color: #888, $alpha: .1);

          div {
            color: rgba($color: var(--contrastColor), $alpha: 1.0);

            svg :is(path, circle) {
              stroke: rgba($color: var(--contrastColor), $alpha: 1.0);
            }
          }
        }

        &:has(>button.danger) {
          margin-top: auto;

          svg :is(path, circle),
          span {
            stroke: red;
            color: red;
          }

          &:hover div svg :is(path, circle) {
            stroke: red;
          }
        }

        &:has(button.danger):hover button.danger {
          background: color-mix(in srgb, red 5%, transparent);
        }

        &>a,
        button {
          width: 100%;
          display: flex;
          background: none;
          border: none;
          justify-content: flex-start;
          align-items: center;
          text-decoration: none;
          border-radius: 10px;
          transition: all .1s ease-in-out;
          overflow: hidden;
          cursor: pointer;

          button.toggleSubmenu {
            position: absolute;
            right: 0;
            height: 50px;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;

            &:hover {
              svg.arrow path {
                stroke: var(--accentColor);
              }
            }

            svg.arrow {
              height: 24px;
              width: 24px;
              transition: all .1s ease-in-out;

              path {
                stroke: var(--mediumEmphasisText);
              }
            }
          }

          div {
            width: 100%;
            height: 50px;
            display: grid;
            grid-template-columns: 24px 1fr;
            align-items: center;
            padding: 0 10px;
            gap: 10px;
            overflow: hidden;

            span {
              font-size: 2rem;
              font-weight: 400;
              color: var(--highEmphasisText);
              text-transform: capitalize;
              text-align: start;
              text-wrap: nowrap;
              white-space: nowrap;
              align-items: flex-end;
              height: 24px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: flex;
            }

            svg {
              height: 2.4rem;
              width: 2.4rem;
              margin-left: 3px;

              path {
                stroke: var(--highEmphasisText);
              }
            }
          }
        }
      }
    }
  }

  section.accentColor {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    width: 100%;
    padding: 10px 15px;
    gap: 10px;
    z-index: 1;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    cursor: pointer;

    &:hover {

      &::after {
        height: calc(100% - 2px);
        background: transparent;
        transition: all .15s ease-in-out .7s;
      }

      span.color {
        transform: translateY(0);
        transition: transform .15s ease-in-out .7s;
      }
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 6px;
      width: calc(100% - 16px);
      height: 10px;
      border-radius: 10px 10px 0 0;
      background: color-mix(in srgb, var(--accentColor) 20%, transparent);
      z-index: 0;
      transition: all .15s ease-in-out;
      border: 2px solid color-mix(in srgb, var(--accentColor) 50%, #111 20%);
      border-bottom: none;
    }

    span.color {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      font-size: 1.6rem;
      width: 20px;
      font-weight: 500;
      border-radius: 10px;
      cursor: pointer;
      background: var(--color);
      transition: transform .1s ease-in-out;
      transform: translateY(40px);
      z-index: 1;

      &:hover,
      &.selected {
        outline: 2px solid var(--color);
        outline-offset: 2px;
      }
    }
  }

  section.profileDetails {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80px;
    padding: 15px;
    background: #fff;
    border-radius: 0 15px 0 0;

    div.card {
      width: 100%;
      height: 100%;
      padding: 10px;
      gap: 10px;
      background: rgba($color: var(--contrastColor), $alpha: 0.1);
      display: grid;
      grid-template-areas: "avatar name" "avatar role";
      grid-template-columns: 50px 1fr;
      grid-template-rows: 1fr 16px;
      border-radius: 10px;
      overflow: hidden;

      span.avatar {
        grid-area: avatar;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--backgroundColor);
        box-shadow: 0 0 10px 0px rgba(220, 220, 220, 0.1);
        padding: 5px;

        &:has(img) {
          padding: 0;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        svg {
          width: 100%;
          height: 100%;

          path {
            stroke: #333;
          }
        }
      }

      span.name {
        grid-area: name;
        font-size: 2.0rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 2.0rem;
        overflow: hidden;
        max-width: 100%;
        text-align: start;
        font-weight: 600;
        color: var(--highEmphasisText);
        align-self: end;
      }

      span.tag {
        grid-area: role;
        font-size: 1.6rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 1.4rem;
        max-width: 100%;
        width: fit-content;
        font-weight: 500;
        color: var(--mediumEmphasisText);
        align-self: start;
      }
    }
  }
}