@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

div.App[data-theme='dark'] {
  --backgroundColor: #121212;

  --highEmphasisText: rgba(255, 255, 255, 0.87);
  --mediumEmphasisText: rgba(255, 255, 255, 0.6);
  --disabledText: rgba(255, 255, 255, 0.38);

  --contrastColor: 255, 255, 255;

  --green: #91D6AC;
  --green2: #95c59a;
  --red: #6a0000;

  --statusGreen: #57F287;
  --statusRed: #ED4245;

  --colorD: #82d882;
  --colorID: #d8d882;
  --colorC: #d88282;
  --colorI: #d882d8;

  --opacity00dp: 0;
  --opacity01dp: 0.05;
  --opacity02dp: 0.07;
  --opacity03dp: 0.08;
  --opacity04dp: 0.09;
  --opacity06dp: 0.11;
  --opacity08dp: 0.12;
  --opacity12dp: 0.14;
  --opacity16dp: 0.15;
  --opacity24dp: 0.16;
}

:root {
  --backgroundColor: #fff;

  --highEmphasisText: rgba(0, 0, 0, 0.87);
  --mediumEmphasisText: rgba(0, 0, 0, 0.6);
  --disabledText: rgba(0, 0, 0, 0.38);

  --contrastColor: 0, 0, 0;

  --green: #91D6AC;
  --green2: #95c59a;
  --red: #6a0000;

  --statusGreen: #57F287;
  --statusRed: #ED4245;

  --colorD: #82d882;
  --colorID: #d8d882;
  --colorC: #d88282;
  --colorI: #d882d8;

  --opacity00dp: 0.5;
  --opacity01dp: 0.55;
  --opacity02dp: 0.57;
  --opacity03dp: 0.58;
  --opacity04dp: 0.59;
  --opacity06dp: 0.61;
  --opacity08dp: 0.62;
  --opacity12dp: 0.64;
  --opacity16dp: 0.65;
  --opacity24dp: 0.66;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  font-display: swap;
  overflow-y: auto;
  overflow-x: hidden;
  height: fit-content;
  min-height: 100svh;
  max-width: 100vw;
}

:focus-visible {
  border-radius: inherit;
  outline: 4px solid color-mix(in srgb, #111 10%, #fff 100%);
}

div.App:not(:has(header#header)) main.body {
  top: 0 !important;
}

main.body {
  top: 60px;
  min-height: calc(100svh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

img {
  vertical-align: middle;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
  background-image: none !important;
  color: inherit !important;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%;
}

* {
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  backface-visibility: hidden;

  *:not(section.fonts *):not(main.dash section.cartell div.preview.assaig *) {
    font-family: 'Josefin Sans', sans-serif;
  }

  section.fonts,
  main.dash section.cartell div.preview.assaig {
    font-family: 'ITC Kabel' !important;
  }
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  --size: 9px;
  background-image: radial-gradient(#ffffff 30%, transparent 30%),
    radial-gradient(#fafafa 30%, transparent 30%);
  background-color: var(--red);
  background-position: 0 0, var(--size) var(--size);
  background-size: calc(var(--size) * 2) calc(var(--size) * 2);
}

*::-webkit-scrollbar-thumb {
  background-color: #1b1b1b;
  background-image: url("./assets/45-degree-fabric-light.png");
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div.App {
  text-align: center;
  min-height: 100svh;
}