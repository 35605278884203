article.noticiaPreview {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	border: 1px solid #e0e0e0;
	border-radius: 8px;
	width: 100%;
	background-color: #fff;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	transition: box-shadow 0.15s ease;
	overflow: hidden;
	gap: 10px;
	height: 100%;

	&:hover {
		box-shadow: 0 0 5px rgba(0, 200, 0, 0.5);
	}

	span.date {
		font-size: 1.2rem;
		gap: 5px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		padding: 0 20px;
		font-weight: 800;
		color: #333;
		margin-bottom: 10px;

		&:before {
			content: '/ Publicat el';
		}
	}

	picture {
		width: 100%;
		height: auto;
		aspect-ratio: 8/2;
		background: #f0f0f0;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	p {
		font-size: 1.4rem;
		padding: 0 20px;
		text-align: justify;
		white-space: normal;
		word-wrap: break-word;
		word-break: break-word;
		color: #333;
		margin-bottom: 20px;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	h2 {
		font-size: 1.6rem;
		color: #000;
		font-weight: 800;
		padding: 0 20px;
		text-align: left;
	}
}

article.noticia {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 20px;

	&>* {
		// border: 1px solid red;
		max-width: 1030px;
		width: 100vw;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		text-align: left;

		@media (max-width: 768px) {
			max-width: calc(100vw - 40px);
		}
	}

	section.share {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
		width: 100%;

		span {
			font-size: 1.6rem;
			color: #333;
		}

		button {
			border: none;
			border-radius: 35%;
			width: 40px;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			transition: background 0.15s ease;

			&:hover svg {
				fill: #fff !important;
			}

			&.x {
				border: 2px solid #000;
				background: transparent;

				&:hover {
					background: #000;
				}

				svg {
					fill: #000;
				}
			}

			&.facebook {
				border: 2px solid #3b5998;
				background: transparent;

				&:hover {
					background: #3b5998;
				}

				svg {
					fill: #3b5998;
				}
			}

			&.whatsapp {
				border: 2px solid #25d366;
				background: transparent;

				&:hover {
					background: #25d366;
				}

				svg {
					width: 24px;
					height: 24px;
					fill: #25d366;
				}
			}

			&:hover {
				background: #e0e0e0;
			}

			svg {
				width: 20px;
				height: 20px;
			}
		}
	}

	section.gallery {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 20px;
		width: 100%;
		flex-wrap: wrap;
		flex-direction: row;

		img {
			flex: 1 1 32%;
			object-fit: cover;
			border-radius: 8px;
			width: 32%;
			max-width: 700px;
		}
	}

	h2 {
		font-size: 2.4rem;
	}

	span.date {
		font-size: 1.4rem;
		gap: 5px;

		&:before {
			content: '/ Publicat el';
		}
	}

	&>picture {
		height: auto;
		background: #f0f0f0;
		width: 100%;
		height: 200px;
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;

		img {
			height: 100%;
			object-fit: cover;

			&:first-child {
				position: absolute;
				opacity: 0.5;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	p {
		display: block;
		font-size: 1.6rem;
		line-height: 1.5;
		font-weight: 300;
		text-align: justify;
		white-space: normal;
		word-wrap: break-word;
		word-break: break-word;

		ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: 0;
			padding: 0 0 0 20px;
			margin: 0;
			flex-direction: column;

			li {
				list-style-type: circle;
				display: list-item;

				&:has(ul) {
					list-style-type: none;
				}
			}
		}

		a {
			color: #4c8bf5;
			text-decoration: none;
			font-weight: bold;
			display: inline;

			&:hover::before {
				content: '';
				position: absolute;
				width: calc(100% + 6px);
				height: calc(100% + 6px);
				background: color-mix(in srgb, #4c8bf5 20%, transparent);
				bottom: -2px;
				left: -3px;
				border-radius: 5px;
			}
		}
	}

	figure {
		height: auto;
		background: #f0f0f0;
		overflow: hidden;
		border-radius: 8px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}